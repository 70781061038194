export default function(context) {
    const { route, redirect, app } = context
    const { locale } = app.i18n
    
    if (route.path.includes('/dubai-insiders-club-index')) {
      if(locale === 'en') {
        return redirect('/')
      }
      return redirect(`/${locale}`) 
    }
  }