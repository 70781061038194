import { render, staticRenderFns } from "./RequestCallTaiwanModal.vue?vue&type=template&id=49f43bb6"
import script from "./RequestCallTaiwanModal.vue?vue&type=script&lang=js"
export * from "./RequestCallTaiwanModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputValidationInput: require('/usr/src/app/components/input/ValidationInput.vue').default,InputPhoneInput: require('/usr/src/app/components/input/PhoneInput.vue').default,Form: require('/usr/src/app/components/form/index.vue').default,CardsExpertCardTaiwan: require('/usr/src/app/components/cards/ExpertCardTaiwan.vue').default,ModalsModalDefault: require('/usr/src/app/components/modals/ModalDefault.vue').default})
