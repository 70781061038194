
import { required } from "vuelidate/lib/validators";
import { apiCreateLead } from "@/services/lead";
import { apiGetAgent } from "@/services/agents";
import toggleModalWindow from "@/mixins/toggleModalWindow";
import handleToken from "@/mixins/handleToken";

export default {
    mixins: [toggleModalWindow, handleToken],
    validations() {
        return {
            lead: {
                name: { required },
                phone: { required },
            },
        };
    },
    data() {
        return {
            lead: {
                name: null,
                phone: null,
            },
            agent: null,
            translations: {
                countrySelectorLabel: "國碼",
                phoneNumberLabel: "電話",
            },
        };
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
    },
    watch: {
        async "$route.query.utm_source"() {
            await this.createLead();
        },
    },
    async mounted() {
        this.$store.commit("startLoading");
        if (this.$store.state.currentAgent) {
            this.agent = this.$store.state.currentAgent;
            this.$store.commit("setCurrentAgent", null);
        } else {
            this.agent = await apiGetAgent();
        }
        this.$store.commit("endLoading");
    },
    methods: {
        async createLead() {
            this.$v.$touch();
            if (!this.$v.lead.$error) {
                if (!this.$route.query.utm_source) {
                    if (this.getToken()) {
                        await this.$router.push({
                            query: {
                                ...this.$route.query,
                                ...this.getToken(),
                            },
                        });
                    } else {
                        await this.$router.push({
                            query: {
                                ...this.$route.query,
                                utm_source: "organic",
                            },
                        });
                    }
                } else {
                    this.$store.commit("startLoading");
                    const response = await apiCreateLead(this.lead);
                    this.$gtm.push({ event: "signUpCompleted" });
                    const data = {
                        ...this.lead,
                        ...this.getToken(),
                        fullUrl: window.location.href.split("?")[0],
                    };

                    if (response) {
                        this.$v.lead.name.$model = null;
                        this.$v.lead.phone.$model = null;
                        this.$nextTick(() => {
                            this.$v.$reset();
                        });
                    }
                    this.closeModal("request-call-modal");
                    this.$store.commit("endLoading");
                    try {
                        await this.$axios({
                            method: "post",
                            url: "postback",
                            baseURL: "/",
                            data,
                        });
                    } catch (err) {}
                }
            }
        },
    },
};
