import Vue from "vue";
import { StripePlugin } from "@vue-stripe/vue-stripe";
const options = {
    pk: process.env.STRIPE_PUBLISHABLE_KEY,
    stripeAccount: process.env.STRIPE_ACCOUNT,
    apiVersion: process.env.API_VERSION,
    locale: process.env.LOCALE,
};
try {
    Vue.use(StripePlugin, options);
} catch ({ message }) {
    console.error(`Stripe error: ${message}`);
}
