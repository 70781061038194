import Vue from 'vue';
import GoogleMap from 'vuejs-google-maps';
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

export default () => {
    const { apiKey, libraries } = {"apiKey":"AIzaSyCeX-TQJpgzPtOwkS0OAehzEql8PaCBT3Q","libraries":["places"]} || {}
    Vue.use(GoogleMap, {
        load: {
            apiKey,
            libraries
        }
    });
}
